(function ($) {

	$.fn.popup = function (params) {
	
		// Set default params
		var defaults = {
			 "closeOnClickOverlay" : true,
			   "closeOnClickPopup" : false,
			  "closeOnClickButton" : false,
			               "cycle" : false,
			              "onLoad" : function () {},
			            "onUnload" : function () {},
			             "onCycle" : function () {}
		};
		
		// Replace defaults with user-defined values
		var params = $.extend(defaults, params);
		
		// Cache common window object
		var $window = $(window);
		
		// Create overlay
		var $overlay = $("<div id='overlay' />");
		
		return this.each(function () {
		
			// Popup object
			var $popup = $(this);
			
			// Make sure the overlay is fullscreen
			stretchOverlay();
			
			// Add close button
			if (params.closeOnClickButton) {
				$popup.append(createCloseButton);
			}		
						
			// Trigger close when overlay is clicked
			if (params.closeOnClickOverlay) {
				$overlay.css("cursor", "pointer").click(function () {
					closePopup();
				});
			}
			
			// Trigger close when popup is clicked
			if (params.closeOnClickPopup) {
				$popup.css("cursor", "pointer").click(function () {
					closePopup();
				});
			}
			
			// Add next/prev buttons
			if (params.cycle) {
				var $group      = $(".popup-group a"),
					groupTotal  = $group.length - 1,
					$nextButton = createNextButton(),
					$prevButton = createPrevButton();
				$popup.append($nextButton, $prevButton);
			}
		
			// Add overlay and popup to the DOM
			$("body").append($overlay, $popup);
			
			// Position popup
			if ($popup.is("img")) {
				$popup.on("load", centerPopup);
			} else {
				centerPopup();				
			}
									
			// Animate in
			$overlay.fadeIn(250, function () {
				$popup.fadeIn(250, function () {
					params.onLoad();
				});
			});
			
			// Watch events
			$window.on("resize.popup", stretchOverlay)
				   .on("resize.popup", centerPopup)
				   .on("keyup.popup", trackKeyPress);
					
			// Position popup
			function centerPopup() {
				var cssTop  = Math.max($window.height() - $popup.outerHeight(), 0) / 2,
					cssLeft = Math.max($window.width() - $popup.outerWidth(), 0) / 2;
				$popup.css({
					 "top" : cssTop + $window.scrollTop(),
					"left" : cssLeft + $window.scrollLeft()
				});
			}
			
			// Close popup and cleanup
			function closePopup() {
				$popup.fadeOut(250, function () {
					$(this).remove();
					$overlay.fadeOut(250, function () {
						$(this).remove();
						if (params.cycle) {
							$group.removeClass("popup-current");
						}
						params.onUnload();
					});
				});
				$window.off("resize.popup", stretchOverlay)
					   .off("resize.popup", centerPopup)
					   .off("keyup.popup", trackKeyPress);
			}
			
			// Add close button and attach click event
			function createCloseButton() {
				return $("<div class='popup-close' />").click(function () {
							closePopup();
						});
			}
			
			// Add next button and attach click event
			function createNextButton() {
				return $("<div class='popup-next' />").click(function () {
							cycleContent(1);						
						});
			}
			
			// Add previous button and attach click event
			function createPrevButton() {
				return $("<div class='popup-prev' />").click(function () {
							cycleContent(-1);
						});
			}
			
			// Advance forward/backward in group
			function cycleContent(num) {
				var $current     = $(".popup-group a.popup-current"),
					currentIndex = $group.index($current),
					newIndex     = 0;
				console.log($current);
				if (currentIndex == groupTotal && num == 1) {
					newIndex = 0;
				} else if (currentIndex == 0 && num == -1) {
					newIndex = groupTotal;
				} else {
					newIndex = currentIndex + num;
				}
				$group.removeClass("popup-current");
				$(".popup-group a:eq(" + newIndex + ")").addClass("popup-current");
				params.onCycle();
			}
			
			// Adjust overlay size to fill screen
			function stretchOverlay() {
				var winW = $window.width(),
					winH = $window.height(),
					docW = $(document).width(),
					docH = $(document).height();
				if (winH < docH) {
					$overlay.css("height", docH + "px");
				} else {
					$overlay.css("height", "100%");
				}
				if (winW < docW) {
					$overlay.css("width", docW + "px");
				} else {
					$overlay.css("width", "100%");
				}
			}
			
			// Watch key events
			function trackKeyPress(e) {
				// Trigger close when Esc pressed
				if (e.keyCode == 27) {
					closePopup();
				}
				// Trigger cycle when arrows pressed
				if (params.cycle) {
					// Next
					if (e.keyCode == 39) {
						console.log('next event');
						cycleContent(1);
					}
					// Previous
					if (e.keyCode == 37) {
						console.log('prev event');
						cycleContent(-1);
					}
				}
			}
		
		});
	
	}

})(jQuery);